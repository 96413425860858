export default {
  color: {
    primary: "#01AED8",
    primaryDarken: "#05A3CA",
    primaryLightest: "#01AED810",
    text: "#1E1D1D",
    textInverse: "#fff",
    bg: "#fff",
    link: "#002630",
    linkInverse: "#fff",
  },
  searchFormFieldHeaderBorder: "var(--color-link)",
  searchFormHeaderIconColor: "var(--color-link)",
  searchFormSubmitHoverBackground: "var(--header-background)",
  searchFormSubmitHoverIconColor: "var(--color-link)",
  linkColor: "var(--color-link)",
  colorAction: "var(--color-link)",
  colorActionActive: "var(--color-link)",
  headerMainMenuColor: "#1E1D1D",
  headerMainTextDecoration: "none",
  headerMainHoverTextDecoration: "underline",
  header: {
    background: "#01AED810",
    color: "var(--color-link)",
  },
  headerFlyOut: {
    toggle: {
      borderColor: "var(--color-primary)",
      color: "#1E1D1D",
      backgroundColor: "var(--color-primary)",
      hover: {
        borderColor: "var(--color-primary-darken)",
        color: "#1E1D1D",
        backgroundColor: "var(--color-primary-darken)",
      },
    },
  },
  headerMainMenu: {
    background: "var(--color-primary)",
    color: "#1E1D1D",
  },
  footer: {
    background: "var(--color-primary)",
    color: "#1E1D1D",
    linkColor: "#1E1D1D",
    dividerBorderTopColor: "var(--color-primary-lightest)",
  },
  gridMenu: {
    backgroundColor: "var(--color-primary)",
  },
  card: {
    background: "var(--color-primary)",
    color: "#1E1D1D",
    titleColor: "#1E1D1D",
    metaColor: "#1E1D1D",
    hover: {
      background: "var(--color-primary-darken)",
      color: "#1E1D1D",
    },
  },
  boxNavigation: {
    linkBackground: "var(--color-primary)",
    linkColor: "#1E1D1D",
    linkHoverColor: "var(--color-link)",
    linkHoverBackground: "var(--color-primary-darken)",
    linkCurrentColor: "#1E1D1D",
  },
  coverLinkColor: "#1E1D1D",
  teaserMetaColor: "#4e4949",
  pageThemeTextColor: "#1E1D1D",
};
